<template>
  <base-material-card
    icon="mdi-clipboard-text"
    :title="detail.name"
    class="px-5 py-3"
    v-if="detail"
  >
    <v-row>
      <v-col cols="6" class="border--right">
        <v-row>
          <v-col cols="6">
            {{ $t('mortgageRequest.name') }}
          </v-col>
          <v-col cols="6">
            {{ detail.name }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            {{ $t('mortgageRequest.phone') }}
          </v-col>
          <v-col cols="6">
            {{ detail.contact_number }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            {{ $t('mortgageRequest.email') }}
          </v-col>
          <v-col cols="6">
            {{ detail.email }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            {{ $t('mortgageRequest.employmentStatus') }}
          </v-col>
          <v-col cols="6">
            {{ detail.employment_status }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            {{ $t('mortgageRequest.totalMonthlyIncome') }}
          </v-col>
          <v-col cols="6">
            {{ totalMonthlyIncome }}
          </v-col>
        </v-row>
        <hr class="my-4" />
        <v-row>
          <v-col cols="6">
            {{ $t('mortgageRequest.bankName') }}
          </v-col>
          <v-col cols="6">
            {{ detail.bank_name }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            {{ $t('mortgageRequest.loanAmount') }}
          </v-col>
          <v-col cols="6">
            {{ $n(detail.loan_amount, 'currency', 'id-ID') }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            {{ $t('mortgageRequest.loanTenure') }}
          </v-col>
          <v-col cols="6">
            {{ detail.loan_tenure }}
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="6">
        <v-row>
          <v-col cols="6">
            {{ $t('mortgageRequest.propertyType') }}
          </v-col>
          <v-col cols="6">
            {{ detail.property_type }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            {{ $t('mortgageRequest.propertyAddress') }}
          </v-col>
          <v-col cols="6">
            {{ detail.property_address }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            {{ $t('mortgageRequest.propertyPrice') }}
          </v-col>
          <v-col cols="6">
            {{ $n(detail.property_price, 'currency', 'id-ID') }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            {{ $t('mortgageRequest.project') }}
          </v-col>
          <v-col cols="6">
            <a :href="websiteUrl + detail.project_url" target="_blank" v-if="detail.project_url">
              {{ websiteUrl + detail.project_url }}
            </a>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            {{ $t('mortgageRequest.projectUnitType') }}
          </v-col>
          <v-col cols="6">
            <a
              :href="websiteUrl + detail.project_unit_type_url"
              target="_blank"
              v-if="detail.project_unit_type_url"
            >
              {{ websiteUrl + detail.project_unit_type_url }}
            </a>
          </v-col>
        </v-row>
        <hr class="my-4" />
        <v-row>
          <v-col cols="6">
            {{ $t('mortgageRequest.createdAt') }}
          </v-col>
          <v-col cols="6">
            {{ detail.created_at ? format(new Date(detail.created_at), 'dd MMMM yyyy HH:mm') : '' }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            {{ $t('mortgageRequest.updatedAt') }}
          </v-col>
          <v-col cols="6">
            {{ detail.updated_at ? format(new Date(detail.updated_at), 'dd MMMM yyyy HH:mm') : '' }}
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </base-material-card>
</template>

<script>
import { mapState } from 'vuex';
import HelperMixin from '@/mixins/helpers';
import { format } from 'date-fns';

export default {
  mixins: [HelperMixin],
  props: ['detail', 'permissions'],
  computed: {
    ...mapState({
      basePath: (state) => state.mortgageRequest.basePath,
      websiteUrl: (state) => state.global.websiteUrl,
    }),
    totalMonthlyIncome() {
      let text = '';
      if (this.detail && this.detail.total_monthly_income_min) {
        if (this.detail.total_monthly_income_max) {
          text =
            this.$n(this.detail.total_monthly_income_min, 'currency', 'id-ID') +
            ' - ' +
            this.$n(this.detail.total_monthly_income_max, 'currency', 'id-ID');
        } else {
          text = this.$n(this.detail.total_monthly_income_min, 'currency', 'id-ID');
        }
      }
      return text;
    },
  },
  data() {
    return {
      format,
    };
  },
  methods: {},
};
</script>
